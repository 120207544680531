<template>
  <div :class="['join', $store.state.lang !== 'zh' ? 'join_en' : '']">
    <!-- p1 -->
    <section class="join-section-1">
      <img class="sec-1-img-1" src="../../assets/images/words@2x.png" alt="">
      <div class="sec-1-words animate__animated animate__bounceInRight">
        <p><span class="sec-1-words-icon">/</span>{{ $t('join.msg_1') }}</p>
      </div>
    </section>
    <!-- p2 -->
    <section class="join-section-2">
      <div class="sec-2-title">{{ $t('join.msg_2') }}</div>
      <div class="sec-2-list">
        <div class="list-title list">
          <div class="item-position">{{ $t('join.msg_3') }}</div>
          <div class="item-department">{{ $t('join.msg_4') }}</div>
          <div class="item-address">{{ $t('join.msg_5') }}</div>
          <div class="item-type">{{ $t('join.msg_6') }}</div>
          <div class="item-time">{{ $t('join.msg_7') }}</div>
        </div>
        <div class="list-item list" v-for="(item, index) in list" :key="index">
          <div class="item-position-phone">
            <p class="item-position-phone-position">{{ $store.state.lang === 'zh' ? item.position : item.position_en }}</p>
            <p class="item-position-phone-department">{{ $store.state.lang === 'zh' ? item.department : item.department_en }}</p>
          </div>
          <div class="item-position">{{ $store.state.lang === 'zh' ? item.position : item.position_en }}</div>
          <div class="item-department">{{ $store.state.lang === 'zh' ? item.department : item.department_en }}</div>
          <div class="item-address">{{ $store.state.lang === 'zh' ? item.address : item.address_en }}</div>
          <!-- 英文版，放不下，隐藏 -->
          <div v-if="!($store.state.lang !== 'zh' && isSmall)" class="item-type">{{ $store.state.lang === 'zh' ? item.type : item.type_en }}</div>
          <div :class="['item-time', ($store.state.lang !== 'zh' && isSmall) ? 'item-time-left' : '']">{{ item.time }}</div>
        </div>
      </div>
      <div class="sec-2-email">{{ $t('join.msg_8') }}：susan.qin@botifyrobot.com</div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [],
    }
  },
  computed: {
    'isSmall': () => {
      const screenWidth = window.screen.width
      const isSmall = screenWidth < 800
      return isSmall
    },
  },
  created () {
    this.list = [
      {
        position: '算法工程师',
        department: '技术部',
        address: '深圳',
        type: '社招',
        position_en: 'Algorithm engineer',
        department_en: 'Technology Department',
        address_en: 'Shenzhen',
        type_en: 'Social recruitment',
        time: '2021.03.20',
      },
      {
        position: '中级嵌入式工程师',
        department: '技术部',
        address: '深圳',
        type: '社招',
        position_en: 'Intermediate Embedded Engineer',
        department_en: 'Technology Department',
        address_en: 'Shenzhen',
        type_en: 'Social recruitment',
        time: '2021.03.20',
      },
      {
        position: '后台软件开发工程师',
        department: '技术部',
        address: '深圳',
        type: '社招',
        position_en: 'Back-end software development engineer',
        department_en: 'Technology Department',
        address_en: 'Shenzhen',
        type_en: 'Social recruitment',
        time: '2021.03.20',
      },
      {
        position: '产品经理',
        department: '技术部',
        address: '深圳',
        type: '社招',
        position_en: 'Product manager',
        department_en: 'Technology Department',
        address_en: 'Shenzhen',
        type_en: 'Social recruitment',
        time: '2021.03.20',
      },
      {
        position: '中级硬件工程师',
        department: '技术部',
        address: '深圳',
        type: '社招',
        position_en: 'Intermediate hardware engineer',
        department_en: 'Technology Department',
        address_en: 'Shenzhen',
        type_en: 'Social recruitment',
        time: '2021.03.20',
      },
      {
        position: '硬件测试工程师',
        department: '技术部',
        address: '深圳',
        type: '社招',
        position_en: 'Hardware test engineer',
        department_en: 'Technology Department',
        address_en: 'Shenzhen',
        type_en: 'Social recruitment',
        time: '2021.03.20',
      },
      {
        position: '算法实习生',
        department: '技术部',
        address: '深圳',
        type: '社招',
        position_en: 'Algorithm intern',
        department_en: 'Technology Department',
        address_en: 'Shenzhen',
        type_en: 'Social recruitment',
        time: '2021.03.20',
      },
      {
        position: '运营实习生-新媒体方向',
        department: '销售部',
        address: '深圳',
        type: '社招',
        position_en: 'Operations Intern-New Media Orientation',
        department_en: 'Sales Department',
        address_en: 'Shenzhen',
        type_en: 'Social recruitment',
        time: '2021.03.20',
      },
      {
        position: '销售经理',
        department: '销售部',
        address: '深圳',
        type: '社招',
        position_en: 'Sales Manager',
        department_en: 'Sales Department',
        address_en: 'Shenzhen',
        type_en: 'Social recruitment',
        time: '2021.03.20',
      },
      {
        position: '销售部实习生',
        department: '销售部',
        address: '深圳',
        type: '社招',
        position_en: 'Sales Intern',
        department_en: 'Sales Department',
        address_en: 'Shenzhen',
        type_en: 'Social recruitment',
        time: '2021.03.20',
      },
    ]
  },
}
</script>

<style lang="less" scope>
// 大屏样式
@media (min-width: 1001px) {
  .join {
    // p1 css
    .join-section-1 {
      height: 10rem;
      background: url('../../assets/images/join-bg@2x.png');
      background-repeat:no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      position: relative;

      .sec-1-img-1 {
        width: .98rem;
        height: 1.16rem;
        position: absolute;
        top: .15rem;
        left: 1.51rem;
      }

      .sec-1-words {
        font-size: 1rem;
        color: #F2F3F4;
        position: absolute;
        top: 6.28rem;
        left: 12.34rem;

        .sec-1-words-icon {
          padding-right: .5rem;
        }
      }
    }
    // p2 css
    .join-section-2 {
      .sec-2-title {
        color: #fff;
        font-size: .38rem;
        text-align: center;
        margin-top: .7rem;
      }

      .sec-2-list {
        margin: .7rem auto .58rem auto;
        width: 12rem;

        .list-title {
          background-color: #006da0;
          font-size: .16rem;
          color: #F2F3F4;
          font-family: 'SourceHanSansCNBold';
        }

        .list-item {
          border-bottom: 1px solid #006da0;
          font-size: .16rem;
          color: #F2F3F4;
        }

        .list {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          height: .8rem;
          line-height: .8rem;
          border-left: 1px solid #006da0;
          border-right: 1px solid #006da0;

          .item-position-phone {
            display: none;
          }

          .item-position {
            width: 4.76rem;
            padding-left: .35rem;
          }

          .item-department {
            width: 2.47rem;
          }

          .item-address {
            width: 1.65rem;
          }

          .item-type {
            width: 1.65rem;
          }

          .item-time {
            width: 1.47rem;
          }
        }
      }

      .sec-2-email {
        font-size: .16rem;
        color: #C2C3C9;
        text-align: center;
        margin-bottom: .45rem;
      }
    }
  }
}
// 小屏样式
@media (max-width: 1000px) {
  .join {
    // p1 css
    .join-section-1 {
      width: 7.5rem;
      height: 10rem;
      background: url('../../assets/images/phone-join-bg@2x.png');
      background-repeat:no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      position: relative;

      .sec-1-img-1 {
        width: .98rem;
        height: 1.16rem;
        position: absolute;
        top: .12rem;
        left: .4rem;
      }
      
      .sec-1-words {
        font-size: .72rem;
        color: #F2F3F4;
        position: absolute;
        font-family: 'SourceHanSansCNBold';
        top: 7.1rem;
        left: 2.7rem;

        .sec-1-words-icon {
          padding-right: .5rem;
        }
      }
    }

    // p2 css
    .join-section-2 {
      .sec-2-title {
        color: #fff;
        font-size: .38rem;
        text-align: center;
        margin-top: .7rem;
      }

      .sec-2-list {
        margin: .7rem auto .58rem auto;
        width: 6.7rem;

        .list-title {
         display: none!important;;
        }

        .list-item {
          background-color: #006da0;
          font-size: .22rem;
          color: #C0C2C4;
          height: .8rem;
          line-height: .8rem;
          margin-bottom: .2rem;
        }

        .list {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          .item-position {
            display: none;
          }

          .item-department {
           display: none;
          }

          .item-position-phone {
            line-height: .4rem;
            padding-left: .2rem;
            width: 3rem;

            .item-position-phone-position {
              color: #F2F3F4;
              font-family: 'SourceHanSansCNBold';
            }
          }

          .item-address {
            width: 1rem;
          }

          .item-type {
            width: 1rem;
          }

          .item-time {
            flex: 1;
          }
        }
      }

      .sec-2-email {
        font-size: .24rem;
        color: #C2C3C9;
        text-align: center;
        margin-bottom: .8rem;
        margin-top: .8rem;
        line-height: .5rem;
      }
    }
  }

  .join_en {
    .list-item {
      height: auto!important;
      min-height: .8rem!important;
    }

    .item-time-left {
      padding-left: .5rem;
    }
  }
}
</style>